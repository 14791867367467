<template>
  <div class="hello">
    <img alt="Vue logo" src="../assets/logo.jpg">
    <h1>{{ msg }}</h1>
    Hi again from the other side ...
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    msg: String
  }
}
</script>

