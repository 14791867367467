<template>
  <div>
    <Welcome msg="Welcome to Flavors Stock App"/>

    <router-link to="/logout">
      Logout
    </router-link>
  </div>
</template>


<script>
import Welcome from '../Welcome.vue'
import UserInfoStore from '../../store/user';

localStorage.setItem('storedData','Home');

export default {
  name: 'Home',
  components: {
    Welcome
  },
  data: function() {
    return{
      userInfo: UserInfoStore.state.cognitoInfo
    }
  }
}
</script>

